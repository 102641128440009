module Main exposing (..)

import Browser
import Browser.Dom as Dom
import Browser.Events
import Common exposing (Guessable, GuessableChar(..), GuessableStatus(..), GuessableString, mostFound, toGuessableString)
import Data.HumanSkeleton as HumanSkeleton
import Helpers.Keyboard as Keyboard
import Helpers.String as String
import Html as H exposing (Html)
import Html.Attributes as HA
import Html.Events as Ev
import Html.Extra as H
import Json.Decode as D
import Page.HumanSkeleton as HumanSkeleton
import Random
import String.Extra as String
import Task
import Time exposing (Posix)



-- MAIN


main : Program () Model Msg
main =
    Browser.element
        { init = init
        , update = update
        , view = view
        , subscriptions = subscriptions
        }



-- MODEL


type alias Model =
    { guessables : List (Guessable (HumanSkeleton.Bone Msg))
    , appMode : Mode
    , hovered : Maybe (Guessable (HumanSkeleton.Bone Msg))
    , appStatus : AppStatus
    }


type AppStatus
    = Loading
    | Running { viewport : Dom.Viewport }


type Mode
    = BrowseMode
    | PlayMode PlayModeData


type alias PlayModeData =
    { maybeCurrentGuess : Maybe String
    , maybeActiveGuessable : Maybe (HumanSkeleton.Bone Msg)
    , hintTimeSettings : ( Int, Int )
    , ticksNextHint : Int
    , currentGuessableAnswer : List GuessableChar
    , revealedGuessableAnswers : List GuessableString
    , phase : PlayPhase
    }


type PlayPhase
    = PreparationPhase
    | PlayingPhase
    | FinishedPhase


init : () -> ( Model, Cmd Msg )
init _ =
    let
        model =
            { guessables = List.map (\p -> { item = p, status = AllRevealed }) HumanSkeleton.bones
            , appMode = BrowseMode
            , hovered = Nothing
            , appStatus = Loading
            }
    in
    ( model
    , Dom.getViewport |> Task.perform GotViewport
    )


initPlayMode : Mode
initPlayMode =
    PlayMode
        { maybeCurrentGuess = Nothing
        , maybeActiveGuessable = Nothing
        , hintTimeSettings = ( 7, 3 )
        , ticksNextHint = 0
        , currentGuessableAnswer = []
        , revealedGuessableAnswers = []
        , phase = PlayingPhase
        }


humanSkeletonConfig : HumanSkeleton.Config Msg
humanSkeletonConfig =
    { onMouseOver = OnHover
    , onMouseOut = OnMouseOut
    , onClickSurface = OnClickSurface
    }



-- UPDATE


type Msg
    = NoOp
    | GotViewport Dom.Viewport
    | WindowResized Int Int
    | Tick Posix
    | StartPlay
    | QuitPlay
    | OnHover (Guessable (HumanSkeleton.Bone Msg))
    | OnMouseOut
    | CommandRequest Command
    | OnKeyDown String
    | OnClickSurface
    | Guess String
    | PickedRandomConcept (HumanSkeleton.Bone Msg)


type Command
    = PickRandom


pickRandomConcept : Model -> ( Model, Cmd Msg )
pickRandomConcept ({ guessables, appMode } as origin) =
    case
        guessables
            |> List.filter (\c -> c.status == Inactive)
            |> List.map (\c -> c.item)
    of
        [] ->
            case appMode of
                BrowseMode ->
                    ( origin, Cmd.none )

                PlayMode playModeData ->
                    ( { origin
                        | appMode =
                            PlayMode
                                { playModeData
                                    | phase = FinishedPhase
                                }
                      }
                    , Cmd.none
                    )

        h :: t ->
            ( origin
            , Random.generate PickedRandomConcept (Random.uniform h t)
            )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg ({ appMode } as model) =
    let
        noChange =
            ( model, Cmd.none )
    in
    case msg of
        NoOp ->
            noChange

        GotViewport viewport ->
            ( { model | appStatus = Running { viewport = viewport } }
            , Cmd.none
            )

        WindowResized _ _ ->
            ( model
            , Dom.getViewport |> Task.perform GotViewport
            )

        Tick _ ->
            case appMode of
                BrowseMode ->
                    noChange

                PlayMode ({ ticksNextHint, currentGuessableAnswer, hintTimeSettings } as playModeData) ->
                    let
                        ( firstHintTime, followingHintTime ) =
                            hintTimeSettings

                        hintTime =
                            if Common.isAllHidden currentGuessableAnswer then
                                firstHintTime

                            else
                                followingHintTime

                        ( ticksNextHint_, isHintTriggered ) =
                            if 1 + ticksNextHint >= hintTime then
                                if Common.isRevealed currentGuessableAnswer then
                                    ( 0, False )

                                else
                                    ( 0, True )

                            else
                                ( 1 + ticksNextHint, False )
                    in
                    ( { model
                        | appMode =
                            PlayMode
                                { playModeData
                                    | ticksNextHint = ticksNextHint_
                                    , currentGuessableAnswer =
                                        if isHintTriggered then
                                            Common.hintNext currentGuessableAnswer

                                        else
                                            currentGuessableAnswer
                                }
                      }
                    , Cmd.none
                    )

        CommandRequest command ->
            case command of
                PickRandom ->
                    noChange

        StartPlay ->
            pickRandomConcept
                { model
                    | guessables = model.guessables |> List.map (\c -> { c | status = Inactive })
                    , appMode = initPlayMode
                }

        QuitPlay ->
            init ()

        OnHover guessable ->
            ( { model
                | hovered = Just guessable
              }
            , Cmd.none
            )

        OnMouseOut ->
            ( { model
                | hovered = Nothing
              }
            , Cmd.none
            )

        PickedRandomConcept item ->
            case appMode of
                PlayMode playModeData ->
                    ( { model
                        | guessables =
                            List.map
                                (\c ->
                                    if item == c.item then
                                        { c | status = Active }

                                    else
                                        c
                                )
                                model.guessables
                        , appMode =
                            PlayMode
                                { playModeData
                                    | maybeActiveGuessable = Just item
                                    , ticksNextHint = 0
                                    , currentGuessableAnswer = toGuessableString (String.toTitleCase item.name)
                                }
                      }
                    , Cmd.none
                    )

                _ ->
                    noChange

        OnKeyDown keycode ->
            let
                key =
                    Keyboard.toKey keycode
            in
            case appMode of
                BrowseMode ->
                    noChange

                PlayMode ({ maybeCurrentGuess } as playModeData) ->
                    case maybeCurrentGuess of
                        Nothing ->
                            case key of
                                Keyboard.Character char ->
                                    ( { model
                                        | appMode =
                                            PlayMode
                                                { playModeData
                                                    | maybeCurrentGuess =
                                                        if char == ' ' then
                                                            Just String.empty

                                                        else
                                                            Just (String.fromChar char)
                                                    , currentGuessableAnswer =
                                                        mostFound
                                                            playModeData.currentGuessableAnswer
                                                            (Common.matchLeftGuessableString (String.fromChar char) playModeData.currentGuessableAnswer)
                                                }
                                      }
                                    , Dom.focus "guess-input" |> Task.attempt (\_ -> NoOp)
                                    )

                                _ ->
                                    ( { model | appMode = PlayMode { playModeData | maybeCurrentGuess = Just String.empty } }
                                    , Dom.focus "guess-input" |> Task.attempt (\_ -> NoOp)
                                    )

                        Just _ ->
                            case key of
                                Keyboard.Control Keyboard.Escape ->
                                    ( { model | appMode = PlayMode { playModeData | maybeCurrentGuess = Nothing } }
                                    , Cmd.none
                                    )

                                _ ->
                                    noChange

        OnClickSurface ->
            case appMode of
                BrowseMode ->
                    noChange

                PlayMode ({ maybeCurrentGuess } as playModeData) ->
                    case maybeCurrentGuess of
                        Nothing ->
                            ( { model | appMode = PlayMode { playModeData | maybeCurrentGuess = Just String.empty } }
                            , Dom.focus "guess-input" |> Task.attempt (\_ -> NoOp)
                            )

                        Just _ ->
                            ( { model | appMode = PlayMode { playModeData | maybeCurrentGuess = Nothing } }
                            , Cmd.none
                            )

        Guess guess ->
            triggerGuess model guess


triggerGuess : Model -> String -> ( Model, Cmd Msg )
triggerGuess ({ appMode } as model) guess =
    case appMode of
        BrowseMode ->
            ( model, Cmd.none )

        PlayMode playModeData ->
            let
                currentGuessableAnswer_ =
                    mostFound
                        playModeData.currentGuessableAnswer
                        (Common.matchLeftGuessableString guess playModeData.currentGuessableAnswer)

                guessables_ =
                    List.map
                        (\g ->
                            if g.status == Active && String.compares guess g.item.name then
                                { g | status = Revealed }

                            else
                                g
                        )
                        model.guessables

                currentGuess =
                    if Common.isLeftFound guess currentGuessableAnswer_ then
                        if String.length guess > Common.lengthGuessable currentGuessableAnswer_ then
                            playModeData.maybeCurrentGuess

                        else
                            Just guess

                    else
                        Common.getFoundString currentGuessableAnswer_ |> Just
            in
            if guessables_ == model.guessables then
                let
                    ticksNextHint_ =
                        if currentGuessableAnswer_ /= playModeData.currentGuessableAnswer then
                            0

                        else
                            playModeData.ticksNextHint
                in
                ( { model
                    | appMode =
                        PlayMode
                            { playModeData
                                | maybeCurrentGuess = currentGuess
                                , ticksNextHint = ticksNextHint_
                                , currentGuessableAnswer = currentGuessableAnswer_
                            }
                  }
                , Cmd.none
                )

            else
                pickRandomConcept
                    { model
                        | guessables = guessables_
                        , appMode =
                            PlayMode
                                { playModeData
                                    | maybeCurrentGuess = Nothing
                                    , ticksNextHint = 0
                                    , revealedGuessableAnswers = currentGuessableAnswer_ :: playModeData.revealedGuessableAnswers
                                }
                    }



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions { appMode } =
    let
        modeSubscriptions =
            case appMode of
                PlayMode _ ->
                    [ Time.every 1000 Tick ]

                _ ->
                    []
    in
    Sub.batch
        ([ Browser.Events.onResize WindowResized
         , Browser.Events.onKeyDown (D.map OnKeyDown (D.field "key" D.string))
         ]
            ++ modeSubscriptions
        )



-- VIEW


viewPlayTrigger : Html Msg
viewPlayTrigger =
    H.button
        [ Ev.onClick StartPlay ]
        [ H.text "Start Quiz" ]


viewQuitPlayTrigger : Html Msg
viewQuitPlayTrigger =
    H.button
        [ Ev.onClick QuitPlay ]
        [ H.text "Reveal all" ]


viewInput : String -> Html Msg
viewInput currentGuess =
    H.input
        [ HA.autocomplete False
        , HA.spellcheck False
        , HA.value currentGuess
        , Ev.onInput Guess
        ]
        []


viewGuessInput : Dom.Viewport -> HumanSkeleton.Bone msg -> Common.GuessableString -> String -> Html Msg
viewGuessInput { viewport } activeGuessable guessableAnswer currentGuess =
    let
        fontSize =
            --64
            round viewport.height // 14

        horizontalPadding =
            let
                scaleFactor =
                    --35
                    toFloat fontSize
                        / 1.8
                        |> round
            in
            (totalWidth // 2) - ((scaleFactor * String.length activeGuessable.name) // 2)

        verticalPadding =
            --32
            fontSize // 2

        totalWidth =
            floor viewport.width

        isWrongGuess : Bool
        isWrongGuess =
            String.length currentGuess == String.length activeGuessable.name && (not <| String.compares currentGuess activeGuessable.name)

        hintColor =
            "#ccc"

        viewHintLayer : Html Never
        viewHintLayer =
            H.div
                [ HA.style "position" "absolute"
                , HA.style "width" "100%"
                , HA.style "top" "50%"
                , HA.style "transform" "translateY(-50%)"
                ]
                [ H.input
                    [ HA.id "guess-hints"
                    , HA.autocomplete False
                    , HA.spellcheck False
                    , HA.value <|
                        String.spaceover
                            (String.length currentGuess)
                            (List.map
                                (\gc ->
                                    case gc of
                                        FoundChar _ ->
                                            String.space

                                        HintedChar c ->
                                            c

                                        HiddenChar c ->
                                            String.scrambleChar c

                                        SpaceChar ->
                                            String.space
                                )
                                guessableAnswer
                                |> String.fromList
                            )
                    , HA.style "outline" "0"
                    , HA.style "border" "0"
                    , HA.style "color" hintColor
                    , if isWrongGuess then
                        HA.class "animate-background-grey-red"

                      else
                        HA.style "background-color" "rgba(34,34,34,0.5)"
                    , HA.style "padding" (String.px verticalPadding ++ " " ++ String.px horizontalPadding)
                    , HA.style "font-size" (String.px fontSize)
                    , HA.class "font-mono"
                    ]
                    []
                ]
    in
    H.div []
        [ H.map never viewHintLayer
        , H.input
            [ HA.id "guess-input"
            , HA.autocomplete False
            , HA.spellcheck False
            , HA.value currentGuess
            , HA.style "position" "absolute"
            , HA.style "width" "100%"
            , HA.style "top" "50%"
            , HA.style "transform" "translateY(-50%)"

            --, HA.style "background-color" "rgba(0,0,0,0.0)"
            , HA.style "outline" "0"
            , HA.style "border" "0"
            , HA.style "background-color" "rgba(0,0,0,0.0)"
            , HA.style "padding" (String.px verticalPadding ++ " " ++ String.px horizontalPadding)
            , HA.style "font-size" (String.px fontSize)
            , HA.style "color" "white"
            , HA.class "font-mono"
            , Ev.onInput Guess
            ]
            []
        ]


viewRevealedGuessableAnswer : GuessableString -> String
viewRevealedGuessableAnswer =
    List.map
        (\gc ->
            case gc of
                FoundChar c ->
                    Char.toLower c

                HintedChar c ->
                    Char.toUpper c

                SpaceChar ->
                    ' '

                HiddenChar _ ->
                    'X'
        )
        >> String.fromList


viewChooseSettingsModal : Html Msg
viewChooseSettingsModal =
    H.div
        [ HA.id "settings-modal"
        , HA.style "position" "absolute"
        , HA.style "width" "400px"
        , HA.style "height" "400px"
        , HA.style "border" "1px solid black"
        ]
        []


viewFinishedModal : PlayModeData -> Html Msg
viewFinishedModal { revealedGuessableAnswers } =
    H.div
        [ HA.id "finished-mod4l"
        , HA.style "position" "absolute"
        , HA.style "width" "400px"
        , HA.style "height" "400px"
        , HA.style "border" "1px black"
        ]
        []


view : Model -> Html Msg
view { guessables, appMode, hovered, appStatus } =
    case appStatus of
        Loading ->
            H.text "Loading"

        Running { viewport } ->
            case appMode of
                BrowseMode ->
                    H.div
                        []
                        [ HumanSkeleton.view viewport
                            { bones = guessables
                            , hovered = hovered
                            , config = humanSkeletonConfig
                            }
                        , H.div
                            [ HA.style "position" "fixed"
                            , HA.style "bottom" "5px"
                            , HA.style "right" "50px"
                            ]
                            [ viewPlayTrigger ]
                        ]

                PlayMode ({ phase, revealedGuessableAnswers, maybeCurrentGuess, maybeActiveGuessable, currentGuessableAnswer } as playModeData) ->
                    case phase of
                        PreparationPhase ->
                            viewChooseSettingsModal

                        FinishedPhase ->
                            H.div
                                [ HA.style "padding" "15px" ]
                                [ H.text <|
                                    (revealedGuessableAnswers
                                        |> List.map Common.countHints
                                        |> List.foldl (+) 0
                                        |> String.fromInt
                                    )
                                        ++ " hints were given, with a hint timer of "
                                        ++ String.fromInt (Tuple.first playModeData.hintTimeSettings)
                                        ++ "/"
                                        ++ String.fromInt (Tuple.second playModeData.hintTimeSettings)
                                        ++ " seconds."
                                , H.br [] []
                                , H.br [] []
                                , H.text <|
                                    String.join ", " (List.map viewRevealedGuessableAnswer revealedGuessableAnswers)
                                ]

                        _ ->
                            H.div
                                [ HA.style "width" "100%"
                                , HA.style "height" "100%"
                                ]
                                [ HumanSkeleton.view viewport
                                    { bones = guessables
                                    , hovered = hovered
                                    , config = humanSkeletonConfig
                                    }
                                , H.div
                                    [ HA.style "position" "fixed"
                                    , HA.style "bottom" "5px"
                                    , HA.style "right" "50px"
                                    ]
                                    [ viewQuitPlayTrigger
                                    ]
                                , case maybeCurrentGuess of
                                    Nothing ->
                                        H.nothing

                                    Just currentGuess ->
                                        case maybeActiveGuessable of
                                            Nothing ->
                                                H.nothing

                                            Just activeGuessable ->
                                                viewGuessInput viewport activeGuessable currentGuessableAnswer currentGuess
                                ]



-- DEBUG


verticalLine : Html Msg
verticalLine =
    H.div
        [ HA.id "vertical-line"
        , HA.style "width" "100%"
        , HA.style "height" "100%"
        , HA.style "position" "absolute"
        ]
        []



{--H.input
                    [ HA.id "guess-hints"
                    , HA.style "position" "absolute"
                    , HA.autocomplete False
                    , HA.spellcheck False
                    , HA.value <|
                        String.spaceover
                            (String.length currentGuess)
                            (List.map
                                (\gc ->
                                    case gc of
                                        HintedChar c ->
                                            c

                                        _ ->
                                            String.space
                                )
                                guessableAnswer
                                |> String.fromList
                            )
                    , HA.style "outline" "0"
                    , HA.style "border" "0"
                    , HA.style "color" hintColor
                    , HA.style "background-color" "rgba(0,0,0,0.0)"
                    , HA.style "padding" (String.px verticalPadding ++ " " ++ String.px horizontalPadding)
                    , HA.style "font-size" (String.px fontSize)
                    , HA.class "font-mono"
                    ]
                    []
                    --}
